import React from 'react';
import { graphql } from 'gatsby';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';

import Layout from '../layouts/index';

import SEO from '../components/SEO';
import Img from 'gatsby-image';

const parseURLName = (name) => {
  let r = name.toLowerCase();
  r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
  r = r.replace(new RegExp(/æ/g), 'ae');
  r = r.replace(new RegExp(/ç/g), 'c');
  r = r.replace(new RegExp(/[èéêë]/g), 'e');
  r = r.replace(new RegExp(/[ìíîï]/g), 'i');
  r = r.replace(new RegExp(/ñ/g), 'n');
  r = r.replace(new RegExp(/[òóôõö]/g), 'o');
  r = r.replace(new RegExp(/œ/g), 'oe');
  r = r.replace(new RegExp(/[ùúûü]/g), 'u');
  r = r.replace(new RegExp(/[ýÿ]/g), 'y');
  r = r.replace(new RegExp(/[()]/g), '');

  return r.replace(/ /gi, '-');
};

function Templates({ data, location }) {
  const publicTemplates = data.allPublicTemplates.edges;
  const { locale } = useLocalization();
  const { t } = useTranslation();
  console.log(data);
  const getImage = (url) => {
    const nodeToUse = data.allImage.edges?.filter(
      ({ node }) => node.url === url
    )?.[0];
    console.log(nodeToUse);
    const result = nodeToUse.node.image.childImageSharp.fixed;

    return result;
  };

  return (
    <Layout
      locale={locale}
      bodyClass="page-home landing"
      inverted
      border
      location={location}
    >
      <SEO title="Plantillas de contratos" />
      <section className="bg-grey">
        <div className="container mt-8">
          <div className="text-center w-75 mx-auto mb-5">
            <h1 className="font-weight-bold" style={{ fontSize: '40px' }}>
              {t('templates.title')}
            </h1>
            <p className="lead text-muted">{t('templates.subtitle')}</p>
            {/* <div className="mx-auto">
                            <form className="w-50">
                                <label class="sr-only" for="inlineFormInputName"></label>
                                <input type="text" class="form-control" id="inlineFormInputName" placeholder="Buscar templates" />
                            </form>
                        </div> */}
          </div>

          <div className="row">
            {/* <div className="col-md-3">
                            <div className="card bg-light border rounded shadow-sm">
                                <div className="card-header border-bottom w-100 p-2">
                                    <h4 className="font-weight-bold mb-0">Categorías</h4>
                                </div>
                                <div className="card-body p-2">
                                    <ul className="list-unstyled">
                                        <li className="mb-2">Arrendamiento</li>
                                        <li className="mb-2">Prestación de servicios</li>
                                        <li className="mb-2">Prestación de servicios</li>
                                        <li className="mb-2">Prestación de servicios</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}

            {publicTemplates.map((template, i) => (
              <div key={template.node._id} className="col-lg-4 col-md-6">
                <LocalizedLink
                  to={`/templates/${parseURLName(template.node.name)}-${
                    template.node.jurisdiction
                  }`}
                  className="d-inline-block card rounded bg-light shadow-sm mb-4"
                >
                  <div className="card-header">
                    {/* <img
                      src={template.node.image || Template.node}
                      className="img-fluid rounded-top"
                      width={350}
                      loading={i > 2 ? 'lazy' : 'eager'}
                      height={248.63}
                      alt={template.node.name}
                    /> */}
                    <Img
                      fixed={getImage(template?.node?.image)}
                      className="img-fluid rounded-top"
                      loading="eager"
                    />
                  </div>
                  <div className="card-body template.node py-2 px-3">
                    <h4 className="font-weight-bold text-dark">
                      {template.node.name}
                    </h4>
                    <span className="badge badge-primary-50 font-weight-thin p-1 mr-1 mb-2">
                      {template.node.type === 'premium'
                        ? 'Plantilla Premium'
                        : 'Plantilla Gratis'}
                    </span>
                    <span className="badge badge-primary-50 font-weight-thin p-1 mb-2">
                      México
                    </span>
                    <p className="text-muted mb-0">
                      {template.node.description}
                    </p>
                  </div>
                </LocalizedLink>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Templates;
// export default Templates;

export const pageQuery = graphql`
  query {
    allPublicTemplates(
      sort: { fields: parent___internal___type, order: DESC }
      filter: { active: { eq: true }, hasBlog: { eq: true } }
    ) {
      edges {
        node {
          _id
          name
          image
          description
          locale
          template {
            _id
          }
          jurisdiction
          type
        }
      }
    }
    allImage {
      edges {
        node {
          id
          url
          image {
            childImageSharp {
              id
              fixed(width: 350, height: 248) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
            name
          }
        }
      }
    }
  }
`;
